import request from '@/utils/request'

// 查询短信模板列表
export function listTemplate(query) {
  return request({
    url: '/extra/sms-template/list',
    method: 'get',
    params: query
  })
}

// 查询短信模板详细
export function getTemplate(id) {
  return request({
    url: '/extra/sms-template/get?id=' + id,
    method: 'get'
  })
}

// 新增短信模板
export function addTemplate(data) {
  return request({
    url: '/extra/sms-template/create',
    method: 'post',
    data: data
  })
}

// 修改短信模板
export function updateTemplate(data) {
  return request({
    url: '/extra/sms-template/update',
    method: 'put',
    data: data
  })
}

// 删除短信模板
export function delTemplate(id) {
  return request({
    url: '/extra/sms-template/delete?id=' + id,
    method: 'delete'
  })
}

// 发送测试短信
export function sendSms(data) {
  return request({
    url: '/extra/sms-template/send-sms',
    method: 'post',
    data: data
  })
}
